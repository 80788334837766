import PatientsIcon from '@mui/icons-material/PeopleOutlineOutlined';
import TriageIcon from '@mui/icons-material/ListOutlined';
import UsersIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import DischargeUsersIcon from '@mui/icons-material/PeopleAlt';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ReportsIcon from '@mui/icons-material/TableChartOutlined';
import {
    ChatIcon,
    DailyAssessmentIconBadge,
    WeeklyAssessmentIconBadge,
    ExerciseSkippedBadge,
    SuitabilityBadge,
    ExerciseFeedbackBadge,
    AdherenceBadge
} from 'components/IconBadge';
import LogIcon from '@mui/icons-material/ViewListOutlined';
import AdherenceIcon from '@mui/icons-material/CardMembershipTwoTone';
import ProgrammesIcon from '@mui/icons-material/Pages';

export const PackageRoutes = (isPlusAgent?: boolean): { [propName: string]: any } => {
    return {
        user_management: {
            name: 'User Management',
            routes: [
                {
                    path: '/dashboard/users',
                    routeName: 'Users',
                    exact: true,
                    icon: UsersIcon
                },
                {
                    path: '/dashboard/audit-logs',
                    routeName: 'Audit Log',
                    exact: true,
                    icon: LogIcon
                }
            ]
        },
        engage_management: {
            name: 'Engage',
            routes: [
                {
                    path: '/dashboard/adherence',
                    exact: true,
                    routeName: 'Adherence',
                    icon: AdherenceBadge
                },
                {
                    path: '/dashboard/suitable-for-treatment',
                    exact: true,
                    routeName: 'Suitability',
                    icon: SuitabilityBadge
                },
                {
                    path: '/dashboard/patients',
                    exact: true,
                    routeName: 'Patients',
                    icon: PatientsIcon
                },
                {
                    path: '/dashboard/daily_assessment',
                    routeName: 'Daily Assessment',
                    icon: DailyAssessmentIconBadge
                },
                {
                    path: '/dashboard/weekly_assessment',
                    routeName: 'Weekly Assessment',
                    icon: WeeklyAssessmentIconBadge
                },
                {
                    path: '/dashboard/skipped_feedback',
                    routeName: 'Skipped Feedback',
                    icon: ExerciseSkippedBadge
                },
                {
                    path: '/dashboard/session_feedback',
                    routeName: 'Session Feedback',
                    icon: ExerciseFeedbackBadge
                },
                {
                    path: '/dashboard/support',
                    exact: true,
                    routeName: 'Support',
                    icon: ChatIcon
                }
            ]
        },
        access_management: {
            name: 'Access',
            routes: isPlusAgent
                ? [
                      {
                          path: '/dashboard/access/triage',
                          exact: true,
                          routeName: 'Triages',
                          icon: AdherenceIcon
                      },
                      {
                          path: '/dashboard/access/patient',
                          exact: true,
                          routeName: 'Patient List',
                          icon: PatientsIcon
                      },
                      {
                          path: '/dashboard/access/about-this-portal',
                          routeName: 'About This Portal',
                          icon: InfoOutlinedIcon
                      }
                  ]
                : [
                      {
                          path: '/dashboard/access/triage',
                          exact: true,
                          routeName: 'Triages',
                          icon: AdherenceIcon
                      },
                      {
                          path: '/dashboard/access/patient',
                          exact: true,
                          routeName: 'Patient List',
                          icon: PatientsIcon
                      }
                  ]
        },
        workflow: {
            name: 'Workflow',
            routes: [
                {
                    path: '/dashboard/phio_access',
                    routeName: 'Triage List',
                    icon: TriageIcon
                },
                {
                    path: '/dashboard/declined_for_engage',
                    routeName: 'Declined List',
                    icon: PatientsIcon
                },
                {
                    path: '/dashboard/discharged_from_engage',
                    routeName: 'Discharged List',
                    icon: DischargeUsersIcon
                }
            ]
        },
        product_configuration: {
            name: 'Configuration',
            routes: [
                {
                    path: '/dashboard/programmes',
                    routeName: 'Programmes',
                    icon: ProgrammesIcon
                }
            ]
        },
        // customer_support: {
        //     name: 'Customer Support',
        //     routes: [
        //     ]
        // },
        management_information: {
            name: 'Management Info',
            routes: [
                {
                    path: '/dashboard/reports',
                    routeName: 'Reports',
                    icon: ReportsIcon
                }
            ]
        },
        collect_management: {
            name: 'Collect',
            routes: [
                {
                    path: '/dashboard/collect',
                    routeName: 'Collections',
                    icon: UsersIcon
                }
            ]
        }
    };
};
