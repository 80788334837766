import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import MaterialTable, { MTableToolbar, Column } from 'material-table';
import { Container, Box, Checkbox } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Triage } from '@eql-ai/typescript-types';
import { useSnackbar } from 'notistack';
import { collection, updateDoc, doc, serverTimestamp } from 'firebase/firestore';
import CursorQuery, { QueryFilter } from 'core/utils/CursorQuery';
import useConfirm from 'hooks/useConfirm';
import { RootState } from '../../redux/reducers';
import { firestoreInstance } from '../../config/ApiService';
import { TRIAGE } from '../../core/utils/collectionNames';
import Filter from 'views/triage/components/Filter';
import DateRangeSelector from 'views/triage/components/DateRangeSelector';
import CustomerDAO from 'core/dao/CustomerDAO';
import CustomerFlagType from 'core/domain/types/CustomerFlagType';
import MultipleFilter from './components/MultipleFilter';
import { LOG_TYPES } from 'utils/Logger';
import AuthActionCreators from 'redux/creators/AuthActionCreators';
import { downloadPdfReport, triagePdfReport } from 'services/pdfService';
import { startOfDay, endOfDay } from 'date-fns';

interface TriageExtended extends Triage {
    name: string;
}

const PhioAccessList = () => {
    const [completedTriages, setCompletedTriagesList] = useState<Triage[]>([]);
    const [startDate, setStartDate] = useState(startOfDay(new Date()));
    const [endDate, setEndDate] = useState(endOfDay(new Date()));
    const [actionedFilter, setActionedFilter] = useState('notActioned');
    const [outcomeFilter, setOutcomeFilter] = useState<string[]>(['All']);
    const [loading, setLoading] = useState(false);
    const [clientActionFlags, setClientActionFlags] = useState<CustomerFlagType[] | undefined>([]);

    const client = useSelector((state: RootState) => state.AuthReducer.organisation);

    const currentRegion = useSelector((state: RootState) => state.AuthReducer.currentRegion);

    const { isConfirmed } = useConfirm();

    const dispatch = useDispatch();

    const { enqueueSnackbar } = useSnackbar();

    const actionFlagsOptions = clientActionFlags?.map((item: any) => item.label) as string[];

    const fetchTriages = useCallback(async () => {
        try {
            setLoading(true);
            const cursorQuery = new CursorQuery(collection(firestoreInstance(), TRIAGE));
            const filtersBase = [
                { field: 'case_status', operator: '==', value: 'completed' },
                { field: 'client_id', operator: '==', value: client.id },
                {
                    field: 'updated_at',
                    operator: '>=',
                    value: startDate
                },
                {
                    field: 'updated_at',
                    operator: '<=',
                    value: endDate
                }
            ] as QueryFilter[];

            const actionedFilterQuery =
                actionedFilter !== 'all'
                    ? [
                          {
                              field: 'actioned',
                              operator: '==',
                              value: actionedFilter === 'actioned' ? true : false
                          }
                      ]
                    : [];

            // 'in' operator is limited to 10 querys
            const outcomeFilterQuery =
                !outcomeFilter.includes('All') &&
                outcomeFilter?.length !== 0 &&
                outcomeFilter?.length <= 10 &&
                outcomeFilter?.length !== actionFlagsOptions?.length
                    ? [
                          {
                              field: 'predicted_outcome',
                              operator: 'in',
                              value: outcomeFilter
                          }
                      ]
                    : [];

            const filters = [
                ...filtersBase,
                ...actionedFilterQuery,
                ...outcomeFilterQuery
            ] as QueryFilter[];

            const data = await cursorQuery.getAllDocuments({
                filters,
                queryOrderBy: { field: 'updated_at', direction: 'desc' },
                queryLimit: 100
            });

            const dataMap = data?.map((doc) => ({
                ...doc.data(),
                id: doc.id,
                name: `${doc.data().patient_first_name || ''} ${doc.data().patient_last_name || ''}`
            })) as TriageExtended[];

            setCompletedTriagesList(dataMap);
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    }, [actionFlagsOptions?.length, actionedFilter, client.id, endDate, outcomeFilter, startDate]);

    const updateFirstDownloadedDate = async (rowData: Triage) => {
        const { id, downloaded_at } = rowData;

        if (!!downloaded_at) {
            return;
        }

        await updateDoc(doc(firestoreInstance(), TRIAGE, id), {
            downloaded_at: serverTimestamp()
        });

        fetchTriages();
    };

    const handleDownloadPDF = async (rowData: Triage) => {
        try {
            setLoading(true);

            const result = await triagePdfReport({ triageId: rowData.id, region: currentRegion });
            const { pdfAsBase64String, status } = result.data;

            if (status === 200) {
                await downloadPdfReport(pdfAsBase64String, `triage-report-${rowData.id}.pdf`);

                await updateFirstDownloadedDate(rowData);
                dispatch(
                    AuthActionCreators.logForAudit({
                        target: {
                            id: rowData.id,
                            description: 'Downloaded Access PDF',
                            affectedUser: rowData.uid
                        },
                        type: LOG_TYPES.DOWNLOADED_PDF
                    })
                );
            } else {
                enqueueSnackbar(
                    'There has been an error downloading the PDF. Please try again, or contact support for a copy of this document.',
                    {
                        variant: 'error'
                    }
                );
            }
        } catch (e) {
            console.error(e);
            enqueueSnackbar(
                'There has been an error downloading the PDF. Please try again, or contact support for a copy of this document.',
                { variant: 'error' }
            );
        } finally {
            setLoading(false);
        }
    };

    const handleActionCheckboxChange = async (data: TriageExtended, checkedState: boolean) => {
        try {
            if (data.actioned && !checkedState) {
                const msg = (
                    <Box sx={{ textAlign: 'center' }}>
                        <p>
                            <b>{`UID: ${data.id}`}</b> <br />
                            <b>Full Name:</b> {`${data.name || ''}`}
                            <br />
                            <br />
                            <span>
                                This patient has been marked as actioned. Are you sure you want to
                                change the status for this patient to “Not Actioned”?
                            </span>
                        </p>
                    </Box>
                );
                // We should ask for confirmation regardless of the action
                const confirm = await isConfirmed(msg);
                if (!confirm) {
                    fetchTriages();
                    return;
                }
            }
            // Lets update the triage data to actioned
            await updateDoc(doc(firestoreInstance(), TRIAGE, data.id), {
                actioned: checkedState
            });
            // Audit log
            dispatch(
                AuthActionCreators.logForAudit({
                    target: {
                        id: data.id,
                        description: checkedState
                            ? 'Phio Access Triage actioned'
                            : 'Phio Access Triage action removed',
                        affectedUser: data.id
                    },
                    type: LOG_TYPES.WORKFLOW
                })
            );
            fetchTriages();
        } catch (e) {
            console.error(e);
        }
    };

    const columns: Column<any>[] = [
        {
            field: 'actioned',
            title: 'Reviewed?',
            align: 'center',
            searchable: false,
            sorting: false,
            render: (rowData: TriageExtended) => {
                return (
                    <Box sx={{ width: '100px', display: 'flex', justifyContent: 'center' }}>
                        <Checkbox
                            checked={rowData.actioned}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                handleActionCheckboxChange(rowData, e.target.checked);
                            }}
                            color="primary"
                            inputProps={{ 'aria-label': 'actioned checkbox' }}
                        />
                    </Box>
                );
            }
        },
        {
            field: 'downloaded_at',
            title: 'First Downloaded',
            emptyValue: 'not downloaded',
            render: (rowData: Triage) => {
                if (rowData?.downloaded_at) {
                    return moment(rowData.downloaded_at.toDate()).format('DD/MM/YYYY HH:mm');
                }
            },
            searchable: false,
            cellStyle: { minWidth: '180px' }
        },
        {
            field: 'name',
            title: 'Name',
            searchable: true,
            cellStyle: { minWidth: '200px' }
        },
        {
            field: 'patient_dob',
            title: 'DoB',
            render: (rowData: Triage) => {
                if (rowData?.patient_dob !== 'missing') {
                    return moment(rowData.patient_dob, 'YYYY-MM-DD').format('DD/MM/YYYY');
                }
                return 'missing';
            },
            searchable: true,
            customFilterAndSearch: (term: any, rowData: Triage) => {
                const dateOfBirth = rowData?.patient_dob;

                const formattedDate = moment(dateOfBirth, 'YYYY-MM-DD').format('DD/MM/YYYY');

                if (dateOfBirth !== 'missing') {
                    return formattedDate.includes(term);
                }
                return dateOfBirth.includes(term);
            },
            cellStyle: { minWidth: '150px' }
        },
        {
            field: 'created_at',
            title: 'Triage Started',
            render: (rowData: Triage) => {
                if (rowData?.created_at) {
                    return moment(rowData.created_at.toDate()).format('DD/MM/YYYY HH:mm');
                }
                return '';
            },
            searchable: false,
            cellStyle: { minWidth: '180px' }
        },
        {
            field: 'updated_at',
            title: 'Triage Last Updated',
            render: (rowData: Triage) => {
                if (rowData?.updated_at) {
                    return moment(rowData.updated_at.toDate()).format('DD/MM/YYYY HH:mm');
                }
                return '';
            },
            searchable: false,
            cellStyle: { minWidth: '180px' }
        },
        {
            field: 'case_status',
            title: 'Case Status',
            searchable: false,
            cellStyle: { minWidth: '140px' }
        },
        {
            field: 'predicted_outcome',
            title: 'Outcome',
            searchable: false,
            editable: 'never',
            cellStyle: { minWidth: '200px' }
        },
        { field: 'uid', title: 'UID', searchable: true, editable: 'never' }
    ];

    const ToolbarFilters = () => (
        <Container maxWidth="xl">
            <Grid container spacing={2} mt={2}>
                <Grid size={{ xs: 6, md: 5, lg: 4 }}>
                    <DateRangeSelector
                        label="Last Updated Date (Start)"
                        defaultDate={startDate}
                        onDateChange={(val) => {
                            setStartDate(startOfDay(val));
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid size={{ xs: 6, md: 5, lg: 4 }}>
                    <DateRangeSelector
                        label="Last Updated Date (End)"
                        defaultDate={endDate}
                        onDateChange={(val) => {
                            setEndDate(endOfDay(val));
                        }}
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={2} mb={3}>
                <Grid size={{ xs: 12, md: 5, lg: 4 }}>
                    <Filter
                        label="Reviewed Status"
                        options={[
                            { id: 'all', name: 'All' },
                            { id: 'actioned', name: 'Actioned' },
                            { id: 'notActioned', name: 'Not Actioned' }
                        ]}
                        onChange={setActionedFilter}
                        fullWidth
                        value={actionedFilter}
                    />
                </Grid>
                <Grid size={{ xs: 12, md: 7, lg: 8 }}>
                    <MultipleFilter
                        label="Triage Outcome"
                        options={actionFlagsOptions ?? []}
                        fullWidth
                        onSelected={(selected: string[]) => setOutcomeFilter(selected)}
                        selectedValue={outcomeFilter}
                    />
                </Grid>
            </Grid>
        </Container>
    );

    useEffect(() => {
        try {
            (async () => {
                // Fetching client info of the patient
                const clientDAO = new CustomerDAO();
                const clientData = await clientDAO.getCustomerInfo(client.id);
                setClientActionFlags(clientData?.flags);
            })();
        } catch (e) {
            console.log(e);
        }
    }, [client.id]);

    useEffect(() => {
        (async () => {
            await fetchTriages();
        })();
    }, [fetchTriages]);

    return (
        <Container maxWidth="xl">
            <MaterialTable
                title="Phio Access"
                data={completedTriages}
                isLoading={loading}
                actions={[
                    {
                        icon: 'save_alt',
                        tooltip: 'Download pdf',
                        onClick: (evt, rowData: any) => {
                            handleDownloadPDF(rowData);
                        }
                    }
                ]}
                components={{
                    Toolbar: (props) => (
                        <>
                            <MTableToolbar {...props} />
                            <ToolbarFilters />
                        </>
                    )
                }}
                columns={columns}
                options={{
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 30],
                    searchFieldVariant: 'outlined',
                    searchFieldStyle: { width: '350px' },
                    headerStyle: { verticalAlign: 'bottom', padding: '16px' }
                }}
                localization={{
                    toolbar: {
                        searchPlaceholder: 'Search by UID, Name or DOB'
                    }
                }}
            />
        </Container>
    );
};

export default PhioAccessList;
