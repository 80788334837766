const isAllNumbers = /\d{10}/;

const validateChiNumber = (chiNumber: string) => {
    const chiNumberNoSpaces = chiNumber.replace(/\s/g, '');

    if (!isAllNumbers.test(chiNumberNoSpaces)) {
        return false;
    }

    // chi number must be 10 characters
    if (chiNumberNoSpaces.length !== 10) {
        return false;
    }

    return true;
};

export default validateChiNumber;
