import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Button,
    Container,
    TextField,
    Avatar,
    Link,
    Theme
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import ArrowBack from '@mui/icons-material/ArrowBack';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    title: {
        flexGrow: 1
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    }
}));

const SignUpContainer = () => {
    const classes = useStyles();
    const navigate = useNavigate();

    return (
        <div className={classes.root}>
            <AppBar position="static" elevation={0}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="menu"
                        onClick={() => {
                            navigate('/signin');
                        }}
                    >
                        <ArrowBack />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Sign in
                    </Typography>
                    <Button color="inherit">EQL</Button>
                </Toolbar>
            </AppBar>
            <main>
                <Container component="main" maxWidth="sm">
                    <div className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign up
                        </Typography>
                        <form className={classes.form} noValidate>
                            <Grid container spacing={2}>
                                <Grid size={12}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="gpName"
                                        label="Business Name"
                                        name="gpName"
                                        autoComplete="gpname"
                                        // onChange={handleChange('gpName')}
                                        // error={!state.gpName}
                                    />
                                </Grid>
                                <Grid size={12}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        name="email"
                                        autoComplete="email"
                                        // onChange={handleChange('email')}
                                        // error={!state.email.match(/@/)}
                                    />
                                </Grid>
                                <Grid size={12}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type="password"
                                        id="password"
                                        autoComplete="current-password"
                                    />
                                </Grid>
                            </Grid>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                Sign Up
                            </Button>
                            <Grid container>
                                <Grid>
                                    <Link href="/signin" variant="body2">
                                        Already have an account? Sign in
                                    </Link>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Container>
            </main>
        </div>
    );
};

export default SignUpContainer;
