import { Container, Paper, Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { makeStyles } from '@mui/styles';

type PatientDetailsProps = {
    patient: {
        phone?: string;
        email?: string;
        postcode?: string;
    };
};

const usePatientDetailsStyles = makeStyles((theme) => ({
    root: {
        position: 'relative'
    },
    paper: {
        margin: theme.spacing(2),
        padding: theme.spacing(2)
    },
    title: {
        fontWeight: 'bold',
        marginRight: theme.spacing(2)
    },
    content: {}
}));

const PatientDetails = (props: PatientDetailsProps) => {
    const { patient } = props;

    const { phone, email, postcode } = patient;

    const classes = usePatientDetailsStyles();

    return (
        <div className={classes.root}>
            <Container maxWidth="xl">
                <Paper className={classes.paper}>
                    <Grid container spacing={2}>
                        <Grid size={3}>
                            <Box mb={2}>
                                <Box component="span" className={classes.title}>
                                    Phone Number
                                </Box>
                                <Box component="span" className={classes.content}>
                                    {phone}
                                </Box>
                            </Box>
                        </Grid>
                        <Grid size={3}>
                            <Box>
                                <Box component="span" className={classes.title}>
                                    Postcode
                                </Box>
                                <Box component="span" className={classes.content}>
                                    {postcode}
                                </Box>
                            </Box>
                        </Grid>
                        <Grid size={12}>
                            <Box mb={2}>
                                <Box component="span" className={classes.title}>
                                    Email Address
                                </Box>
                                <Box component="span" className={classes.content}>
                                    {email}
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </div>
    );
};

export default PatientDetails;
