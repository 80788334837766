import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { parse } from 'date-fns';

interface Props {
    label?: string;
    date?: string;
    onDateInputChange?: (date: Date | null) => void;
    error?: {
        message?: string;
    };
    required?: boolean;
    inputFormat?: string;
    dateFormat: string;
}

const PortalDatePicker = (props: Props) => {
    const { label, date, onDateInputChange, required, inputFormat, dateFormat } = props;
    const [value, setValue] = React.useState<Date | null>(
        date ? parse(date, dateFormat, new Date()) : null
    );

    const handleChange = (newValue: Date | null) => {
        setValue(newValue);
        if (onDateInputChange) {
            onDateInputChange(newValue);
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                label={label || 'Date Picker'}
                format={inputFormat || 'MM/dd/yyyy'}
                onChange={handleChange}
                value={value}
                slotProps={{
                    textField: {
                        helperText: props.error?.message,
                        error: props.error ? true : false,
                        required: required,
                        fullWidth: true
                    }
                }}
            />
        </LocalizationProvider>
    );
};

export default PortalDatePicker;
