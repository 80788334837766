import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import MaterialTable, { MTableToolbar, Column } from 'material-table';
import { Container, Box, Typography, Checkbox } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Triage } from '@eql-ai/typescript-types';
import { collection, updateDoc, doc } from 'firebase/firestore';
import CursorQuery, { QueryFilter } from 'core/utils/CursorQuery';
import useConfirm from 'hooks/useConfirm';
import { RootState } from '../../redux/reducers';
import { firestoreInstance } from '../../config/ApiService';
import { TRIAGE } from '../../core/utils/collectionNames';
import Filter from 'views/triage/components/Filter';
import DateRangeSelector from 'views/triage/components/DateRangeSelector';
import TruncatedText from './components/TruncatedText';
import { LOG_TYPES } from 'utils/Logger';
import AuthActionCreators from 'redux/creators/AuthActionCreators';
import { startOfDay, endOfDay } from 'date-fns';

interface TriageExtended extends Triage {
    name: string;
    review_date: string;
    actioned_declined_for_engage?: boolean;
}

const DeclineForEngageList = () => {
    const [notSuitableTriages, setTriagesList] = useState<Triage[]>([]);
    const [startDate, setStartDate] = useState(startOfDay(new Date()));
    const [endDate, setEndDate] = useState(endOfDay(new Date()));
    const [actionedFilter, setActionedFilter] = useState('notActioned');
    const [loading, setLoading] = useState(false);

    const client = useSelector((state: RootState) => state.AuthReducer.organisation);

    const { isConfirmed } = useConfirm();

    const dispatch = useDispatch();

    const fetchTriages = useCallback(async () => {
        try {
            setLoading(true);
            const cursorQuery = new CursorQuery(collection(firestoreInstance(), TRIAGE));
            const filtersBase = [
                { field: 'client_id', operator: '==', value: client.id },
                { field: 'predicted_outcome', operator: '==', value: 'Self Management' },
                { field: 'suitable_for_treatment', operator: '==', value: false },
                {
                    field: 'metadata.date',
                    operator: '>=',
                    value: startDate
                },
                {
                    field: 'metadata.date',
                    operator: '<=',
                    value: endDate
                }
            ] as QueryFilter[];

            const actionedFilterQuery =
                actionedFilter !== 'all'
                    ? [
                          {
                              field: 'actioned_declined_for_engage',
                              operator: '==',
                              value: actionedFilter === 'actioned' ? true : false
                          }
                      ]
                    : [];

            const filters = [...filtersBase, ...actionedFilterQuery] as QueryFilter[];

            const data = await cursorQuery.getAllDocuments({
                filters,
                queryOrderBy: { field: 'metadata.date', direction: 'desc' },
                queryLimit: 100
            });

            const dataMap = data?.map((doc) => {
                const data = doc.data();
                return {
                    ...data,
                    id: doc.id,
                    name: `${data.patient_first_name || ''} ${data.patient_last_name || ''}`,
                    review_date: data.metadata.date
                        ? moment(data.metadata.date.toDate()).format('DD/MM/YYYY HH:mm')
                        : ''
                };
            }) as TriageExtended[];

            setTriagesList(dataMap);
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    }, [actionedFilter, client.id, endDate, startDate]);

    const handleActionCheckboxChange = async (data: TriageExtended, checkedState: boolean) => {
        try {
            if (data.actioned_declined_for_engage && !checkedState) {
                const msg = (
                    <Box sx={{ textAlign: 'center' }}>
                        <p>
                            <b>{`UID: ${data.id}`}</b> <br />
                            <b>Full Name:</b> {`${data.name || ''}`}
                            <br />
                            <br />
                            <span>
                                This patient has been marked as actioned. Are you sure you want to
                                change the status for this patient to “Not Actioned”?
                            </span>
                        </p>
                    </Box>
                );
                // We should ask for confirmation regardless of the action
                const confirm = await isConfirmed(msg);
                if (!confirm) {
                    fetchTriages();
                    return;
                }
            }
            // Lets update the triage data to actioned
            await updateDoc(doc(firestoreInstance(), TRIAGE, data.id), {
                actioned_declined_for_engage: checkedState
            });
            // Audit log
            dispatch(
                AuthActionCreators.logForAudit({
                    target: {
                        id: data.id,
                        description: checkedState
                            ? 'Not Approved actioned'
                            : 'Not Approved action removed',
                        affectedUser: data.id
                    },
                    type: LOG_TYPES.WORKFLOW
                })
            );
            fetchTriages();
        } catch (e) {
            console.error(e);
        }
    };

    const columns: Column<any>[] = [
        {
            field: 'actioned_declined_for_engage',
            title: 'Reviewed?',
            align: 'center',
            searchable: false,
            sorting: false,
            render: (rowData: TriageExtended) => {
                return (
                    <Box sx={{ width: '100px', display: 'flex', justifyContent: 'center' }}>
                        <Checkbox
                            checked={rowData.actioned_declined_for_engage}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                handleActionCheckboxChange(rowData, e.target.checked);
                            }}
                            color="primary"
                            inputProps={{ 'aria-label': 'actioned checkbox' }}
                        />
                    </Box>
                );
            }
        },
        { field: 'uid', title: 'Access UID', searchable: true, editable: 'never' },
        {
            field: 'name',
            title: 'Name',
            searchable: true,
            cellStyle: { minWidth: '200px' }
        },
        {
            field: 'patient_dob',
            title: 'DoB',
            render: (rowData: Triage) => {
                if (rowData?.patient_dob !== 'missing') {
                    return moment(rowData.patient_dob, 'YYYY-MM-DD').format('DD/MM/YYYY');
                }
                return 'missing';
            },
            searchable: true,
            cellStyle: { minWidth: '150px' },
            customFilterAndSearch: (term: any, rowData: Triage) => {
                const dateOfBirth = rowData?.patient_dob;

                const formattedDate = moment(dateOfBirth, 'YYYY-MM-DD').format('DD/MM/YYYY');

                if (dateOfBirth !== 'missing') {
                    return formattedDate.includes(term);
                }
                return dateOfBirth.includes(term);
            }
        },
        {
            field: 'clinician_notes',
            title: 'Clinical Notes',
            emptyValue: 'no nots added',
            render: (rowData: Triage) => {
                if (rowData?.clinician_notes) {
                    return <TruncatedText text={rowData?.clinician_notes} />;
                }
            },
            searchable: false,
            sorting: false,
            cellStyle: { minWidth: '250px' }
        },
        {
            field: 'clinician_reason',
            title: 'Message to Patient',
            emptyValue: 'no message added',
            render: (rowData: Triage) => {
                if (rowData?.clinician_reason) {
                    return <TruncatedText text={rowData?.clinician_reason} />;
                }
            },
            searchable: false,
            sorting: false,
            cellStyle: { minWidth: '250px' }
        },
        {
            field: 'review_date',
            title: 'Review Date',
            type: 'datetime',
            emptyValue: 'not reviewed',
            searchable: false,
            sorting: false,
            cellStyle: { minWidth: '180px' }
        }
    ];

    const ToolbarFilters = () => (
        <Container maxWidth="xl">
            <Grid container spacing={2} mt={2} mb={3}>
                <Grid size={{ xs: 6, md: 5, lg: 4 }}>
                    <DateRangeSelector
                        label="Review Date (Start)"
                        defaultDate={startDate}
                        onDateChange={(val) => {
                            setStartDate(startOfDay(val));
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid size={{ xs: 6, md: 5, lg: 4 }}>
                    <DateRangeSelector
                        label="Review Date (End)"
                        defaultDate={endDate}
                        onDateChange={(val) => {
                            setEndDate(endOfDay(val));
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid size={{ xs: 12, md: 5, lg: 4 }}>
                    <Filter
                        label="Reviewed Status"
                        options={[
                            { id: 'all', name: 'All' },
                            { id: 'actioned', name: 'Actioned' },
                            { id: 'notActioned', name: 'Not Actioned' }
                        ]}
                        onChange={setActionedFilter}
                        fullWidth
                        value={actionedFilter}
                    />
                </Grid>
            </Grid>
        </Container>
    );

    useEffect(() => {
        (async () => {
            await fetchTriages();
        })();
    }, [fetchTriages]);

    return (
        <Container maxWidth="xl">
            <MaterialTable
                title="Not Approved for Engage"
                data={notSuitableTriages}
                isLoading={loading}
                components={{
                    Toolbar: (props) => (
                        <>
                            <MTableToolbar {...props} />
                            <ToolbarFilters />
                        </>
                    )
                }}
                columns={columns}
                options={{
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 30],
                    searchFieldVariant: 'outlined',
                    searchFieldStyle: { width: '350px' },
                    exportButton: true,
                    exportAllData: true,
                    headerStyle: { verticalAlign: 'bottom', padding: '16px' },
                    exportFileName: `Not Approved for Engage ${moment(startDate).format(
                        'DD/MM/YYYY'
                    )} - ${moment(endDate).format('DD/MM/YYYY')}`
                }}
                localization={{
                    toolbar: {
                        searchPlaceholder: 'Search by UID, Name or DOB'
                    }
                }}
                detailPanel={(rowData) => {
                    return (
                        <Container maxWidth="xl">
                            <Grid container justifyContent="center" spacing={2}>
                                <Grid size={{ xs: 8, md: 5 }}>
                                    <Box my={3}>
                                        <Typography
                                            variant="h6"
                                            component="p"
                                            color="secondary"
                                            gutterBottom
                                        >
                                            Clinical Notes
                                        </Typography>
                                        <Typography variant="body1" component="p">
                                            {rowData?.clinician_notes || 'no notes'}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid size={{ xs: 8, md: 5 }}>
                                    <Box my={3}>
                                        <Typography
                                            variant="h6"
                                            component="p"
                                            color="secondary"
                                            gutterBottom
                                        >
                                            Message to Patient
                                        </Typography>
                                        <Typography variant="body1" component="p">
                                            {rowData?.clinician_reason || 'no message'}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Container>
                    );
                }}
            />
        </Container>
    );
};

export default DeclineForEngageList;
