import React from 'react';
import { createRoot } from 'react-dom/client';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@mui/material';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from 'App';
import Theme from 'config/theme';
import store, { persistor } from 'redux/store/store';
import * as serviceWorker from './serviceWorker';
import ConfirmContextProvider from 'contexts/ConfirmContextProvider';
import ConfirmDialog from 'components/ConfirmDialog';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ThemeProvider theme={Theme}>
                    <SnackbarProvider maxSnack={3}>
                        <ConfirmContextProvider>
                            <App />
                            <ConfirmDialog />
                        </ConfirmContextProvider>
                    </SnackbarProvider>
                </ThemeProvider>
            </PersistGate>
        </Provider>
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
