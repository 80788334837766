import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useState } from 'react';
import { addYears, isValid, isWithinInterval } from 'date-fns';

interface DateRangeSelectorProps {
    fullWidth?: boolean;
    label?: string;
    defaultDate?: Date;
    onDateChange?: (date: Date) => void;
    error?: {
        message?: string;
    };
    required?: boolean;
    inputFormat?: string;
}

const DateRangeSelector = (props: DateRangeSelectorProps) => {
    const { label, onDateChange, required, inputFormat, fullWidth, error, defaultDate } = props;

    const [value, setValue] = useState<Date | null>(defaultDate ? defaultDate : new Date());
    const [validDateError, setValidDateError] = useState<string>('');

    const handleChange = (newValue: Date | null) => {
        if (!newValue) {
            return;
        }

        const isValidDate =
            isValid(newValue) &&
            isWithinInterval(newValue, {
                start: new Date(1900, 0, 1),
                end: addYears(new Date(), 10)
            });

        if (!isValidDate) {
            setValidDateError('Invalid date');
            return;
        }
        setValidDateError('');
        setValue(newValue);
        if (onDateChange && newValue) {
            onDateChange(newValue);
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                label={label || 'Date Picker'}
                format={inputFormat || 'dd/MM/yyyy'}
                onChange={handleChange}
                value={value}
                slotProps={{
                    textField: {
                        helperText: error?.message || validDateError,
                        error: error || validDateError ? true : false,
                        required: required,
                        fullWidth: fullWidth
                    }
                }}
            />
        </LocalizationProvider>
    );
};

export default DateRangeSelector;

// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { useState } from 'react';
// import { addYears, isValid, isWithinInterval } from 'date-fns';
// import dayjs, { Dayjs } from 'dayjs';

// interface DateRangeSelectorProps {
//     fullWidth?: boolean;
//     label?: string;
//     defaultDate?: Dayjs;
//     onDateChange?: (date: Dayjs) => void;
//     error?: {
//         message?: string;
//     };
//     required?: boolean;
//     inputFormat?: string;
// }

// const DateRangeSelector = (props: DateRangeSelectorProps) => {
//     const { label, onDateChange, required, inputFormat, fullWidth, error, defaultDate } = props;

//     const [value, setValue] = useState<Dayjs | null>(defaultDate ? defaultDate : dayjs());
//     const [validDateError, setValidDateError] = useState<string>('');

//     const handleChange = (newValue: Dayjs | null) => {
//         if (!newValue) {
//             return;
//         }

//         const isValidDate =
//             dayjs(newValue).isValid() &&
//             isWithinInterval(newValue, {
//                 start: dayjs(new Date(1900, 0, 1)),
//                 end: dayjs().add(10, 'year')
//             });

//         if (!isValidDate) {
//             setValidDateError('Invalid date');
//             return;
//         }
//         setValidDateError('');
//         setValue(newValue);
//         if (onDateChange && newValue) {
//             onDateChange(newValue);
//         }
//     };

//     return (
//         <LocalizationProvider dateAdapter={AdapterDayjs}>
//             <DatePicker
//                 label={label || 'Date Picker'}
//                 format={inputFormat || 'dd/MM/yyyy'}
//                 onChange={handleChange}
//                 value={value}
//                 slotProps={{
//                     textField: {
//                         helperText: error?.message || validDateError,
//                         error: error || validDateError ? true : false,
//                         required: required,
//                         fullWidth: fullWidth
//                     }
//                 }}
//             />
//         </LocalizationProvider>
//     );
// };

// export default DateRangeSelector;
