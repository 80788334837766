import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import MaterialTable, { MTableToolbar, Column } from 'material-table';
import { Container, Box, Typography, Checkbox } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { TreatmentPlan } from '@eql-ai/typescript-types';
import { collection, updateDoc, doc } from 'firebase/firestore';
import CursorQuery, { QueryFilter } from 'core/utils/CursorQuery';
import useConfirm from 'hooks/useConfirm';
import { RootState } from '../../redux/reducers';
import { firestoreInstance } from '../../config/ApiService';
import { TREATMENT_PLANS } from '../../core/utils/collectionNames';
import Filter from 'views/triage/components/Filter';
import DateRangeSelector from 'views/triage/components/DateRangeSelector';
import MultipleFilter from './components/MultipleFilter';
import TruncatedText from './components/TruncatedText';
import { LOG_TYPES } from 'utils/Logger';
import AuthActionCreators from 'redux/creators/AuthActionCreators';
import { engageDischargePdfReport, downloadPdfReport } from 'services/pdfService';
import { useSnackbar } from 'notistack';
import { startOfDay, endOfDay } from 'date-fns';

const dischargeCodes = [
    'Discharge - Onward Referral',
    'Discharge - Recovered',
    'Discharge - Non Compliant',
    'Discharge - Failed Capture',
    'Discharge - Programme Complete',
    'Discharge - Other'
];

interface TreatmentPlanExtended extends TreatmentPlan {
    name: string;
    actioned_discharged: boolean;
    start_treatment_date: string;
    discharged_date: string;
}

const DischargedFromEngageList = () => {
    const [treatmentPlans, setTreatmentPlans] = useState<TreatmentPlan[]>([]);
    const [startDate, setStartDate] = useState(startOfDay(new Date()));
    const [endDate, setEndDate] = useState(endOfDay(new Date()));
    const [actionedFilter, setActionedFilter] = useState('notActioned');
    const [dischargeCodeFilter, setDischargeCodeFilter] = useState<string[]>(['All']);
    const [loading, setLoading] = useState(false);

    const client = useSelector((state: RootState) => state.AuthReducer.organisation);
    const currentRegion = useSelector((state: RootState) => state.AuthReducer.currentRegion);

    const { isConfirmed } = useConfirm();

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const fetchTreatmentPlan = useCallback(async () => {
        try {
            setLoading(true);
            const cursorQuery = new CursorQuery(collection(firestoreInstance(), TREATMENT_PLANS));
            const filtersBase = [
                { field: 'case_status', operator: '==', value: 'discharged' },
                { field: 'client_id', operator: '==', value: client.id },
                {
                    field: 'case_closed_at',
                    operator: '>=',
                    value: startDate
                },
                {
                    field: 'case_closed_at',
                    operator: '<=',
                    value: endDate
                }
            ] as QueryFilter[];

            const actionedFilterQuery =
                actionedFilter !== 'all'
                    ? [
                          {
                              field: 'actioned_discharged',
                              operator: '==',
                              value: actionedFilter === 'actioned' ? true : false
                          }
                      ]
                    : [];

            // 'in' operator is limited to 10 queries
            const dischargeCodeFilterQuery =
                !dischargeCodeFilter.includes('All') &&
                dischargeCodeFilter?.length !== 0 &&
                dischargeCodeFilter?.length <= 10 &&
                dischargeCodeFilter?.length !== dischargeCodes?.length
                    ? [
                          {
                              field: 'case_status_code',
                              operator: 'in',
                              value: dischargeCodeFilter
                          }
                      ]
                    : [];

            const filters = [
                ...filtersBase,
                ...actionedFilterQuery,
                ...dischargeCodeFilterQuery
            ] as QueryFilter[];

            const data = await cursorQuery.getAllDocuments({
                filters,
                queryOrderBy: { field: 'case_closed_at', direction: 'desc' },
                queryLimit: 100
            });

            const dataMap = data?.map((doc) => {
                const docData = doc.data();

                return {
                    ...docData,
                    id: doc.id,
                    name: `${docData.first_name || ''} ${docData.last_name || ''}`,
                    start_treatment_date:
                        docData?.start_date && typeof docData?.start_date !== 'string'
                            ? moment(docData.start_date.toDate()).format('DD/MM/YYYY HH:mm')
                            : '',
                    discharged_date: docData?.case_closed_at
                        ? moment(docData.case_closed_at.toDate()).format('DD/MM/YYYY HH:mm')
                        : ''
                };
            }) as any[];

            setTreatmentPlans(dataMap);
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    }, [actionedFilter, client.id, dischargeCodeFilter, endDate, startDate]);

    const handleDownloadPDF = async (rowData: TreatmentPlan) => {
        setLoading(true);

        if (!rowData?.id) {
            return;
        }

        const result = await engageDischargePdfReport({
            patientId: rowData.id,
            region: currentRegion
        });
        const { pdfAsBase64String, status } = result.data;

        if (status === 200) {
            await downloadPdfReport(
                pdfAsBase64String,
                `Patient Report - ${rowData.first_name} ${rowData.last_name}.pdf`
            );

            dispatch(
                AuthActionCreators.logForAudit({
                    target: {
                        id: rowData.id,
                        description: 'Downloaded Discharge PDF',
                        affectedUser: rowData.id
                    },
                    type: LOG_TYPES.DOWNLOADED_PDF
                })
            );
        } else {
            enqueueSnackbar('There was an issue downloading this PDF, please try again.', {
                variant: 'error'
            });
        }

        setLoading(false);
    };

    const handleActionCheckboxChange = async (
        data: TreatmentPlanExtended,
        checkedState: boolean
    ) => {
        try {
            if (data.actioned_discharged && !checkedState) {
                const msg = (
                    <Box sx={{ textAlign: 'center' }}>
                        <p>
                            <b>{`UID: ${data.id}`}</b> <br />
                            <b>Full Name:</b> {`${data.name || ''}`}
                            <br />
                            <br />
                            <span>
                                This patient has been marked as actioned. Are you sure you want to
                                change the status for this patient to “Not Actioned”?
                            </span>
                        </p>
                    </Box>
                );
                // We should ask for confirmation regardless of the action
                const confirm = await isConfirmed(msg);
                if (!confirm) {
                    fetchTreatmentPlan();
                    return;
                }
            }
            // Lets update the treatment plan data to actioned
            await updateDoc(doc(firestoreInstance(), TREATMENT_PLANS, data.id), {
                actioned_discharged: checkedState
            });
            // Audit log
            dispatch(
                AuthActionCreators.logForAudit({
                    target: {
                        id: data.id,
                        description: checkedState
                            ? 'Discharge actioned'
                            : 'Discharge action removed',
                        affectedUser: data.id
                    },
                    type: LOG_TYPES.WORKFLOW
                })
            );
            fetchTreatmentPlan();
        } catch (e) {
            console.error(e);
        }
    };

    const columns: Column<any>[] = [
        {
            field: 'actioned_discharged',
            title: 'Reviewed?',
            type: 'boolean',
            align: 'center',
            render: (rowData: TreatmentPlanExtended) => {
                return (
                    <Box sx={{ width: '100px', display: 'flex', justifyContent: 'center' }}>
                        <Checkbox
                            checked={rowData.actioned_discharged}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                handleActionCheckboxChange(rowData, e.target.checked);
                            }}
                            color="primary"
                            inputProps={{ 'aria-label': 'actioned checkbox' }}
                        />
                    </Box>
                );
            },
            searchable: false,
            sorting: false
        },
        { field: 'id', title: 'Engage UID', searchable: true, editable: 'never', sorting: false },
        {
            field: 'case_status_code',
            title: 'Discharge Code',
            searchable: false,
            sorting: false,
            cellStyle: { minWidth: '200px' }
        },
        {
            field: 'name',
            title: 'Name',
            searchable: true,
            cellStyle: { minWidth: '200px' }
        },
        {
            field: 'dob',
            title: 'DoB',
            searchable: true,
            cellStyle: { minWidth: '150px' },
            emptyValue: 'missing'
        },
        {
            field: 'start_treatment_date',
            title: 'Start Date',
            cellStyle: { minWidth: '180px' },
            searchable: false,
            sorting: false
        },
        {
            field: 'discharged_date',
            title: 'Discharge Date',
            searchable: false,
            sorting: false,
            cellStyle: { minWidth: '180px' }
        },
        {
            field: 'case_notes',
            title: 'Customer Notes',
            emptyValue: 'no nots added',
            render: (rowData: TreatmentPlan) => {
                if (rowData?.case_notes) {
                    return <TruncatedText text={rowData?.case_notes} />;
                }
            },
            searchable: false,
            sorting: false,
            cellStyle: { minWidth: '250px' }
        }
    ];

    const ToolbarFilters = () => (
        <Container maxWidth="xl">
            <Grid container spacing={2} mt={2}>
                <Grid size={{ xs: 6, md: 5, lg: 4 }}>
                    <DateRangeSelector
                        label="Discharge Date (Start)"
                        defaultDate={startDate}
                        onDateChange={(val) => {
                            setStartDate(startOfDay(val));
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid size={{ xs: 6, md: 5, lg: 4 }}>
                    <DateRangeSelector
                        label="Discharge Date (End)"
                        defaultDate={endDate}
                        onDateChange={(val) => {
                            setEndDate(endOfDay(val));
                        }}
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={2} mb={3}>
                <Grid size={{ xs: 12, md: 5, lg: 4 }}>
                    <Filter
                        label="Reviewed Status"
                        options={[
                            { id: 'all', name: 'All' },
                            { id: 'actioned', name: 'Actioned' },
                            { id: 'notActioned', name: 'Not Actioned' }
                        ]}
                        onChange={setActionedFilter}
                        fullWidth
                        value={actionedFilter}
                    />
                </Grid>
                <Grid size={{ xs: 12, md: 7, lg: 8 }}>
                    <MultipleFilter
                        label="Discharge code"
                        options={dischargeCodes}
                        fullWidth
                        onSelected={(selected: string[]) => setDischargeCodeFilter(selected)}
                        selectedValue={dischargeCodeFilter}
                        showHelperOnlyOnError
                    />
                </Grid>
            </Grid>
        </Container>
    );

    useEffect(() => {
        (async () => {
            await fetchTreatmentPlan();
        })();
    }, [fetchTreatmentPlan]);

    return (
        <Container maxWidth="xl">
            <MaterialTable
                title="Discharged from Engage"
                data={treatmentPlans}
                isLoading={loading}
                actions={[
                    {
                        icon: 'save_alt',
                        tooltip: 'Download pdf',
                        onClick: (evt, rowData: any) => {
                            handleDownloadPDF(rowData);
                        }
                    }
                ]}
                components={{
                    Toolbar: (props) => (
                        <>
                            <MTableToolbar {...props} />
                            <ToolbarFilters />
                        </>
                    )
                }}
                columns={columns}
                options={{
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 30],
                    searchFieldVariant: 'outlined',
                    searchFieldStyle: { width: '350px' },
                    exportButton: true,
                    exportAllData: true,
                    headerStyle: {
                        verticalAlign: 'bottom',
                        paddingBottom: '16px',
                        paddingRight: '16px'
                    },
                    exportFileName: `Discharged from Engage ${moment(startDate).format(
                        'DD/MM/YYYY'
                    )} - ${moment(endDate).format('DD/MM/YYYY')}`
                }}
                localization={{
                    toolbar: {
                        searchPlaceholder: 'Search by UID, Name or DOB'
                    }
                }}
                detailPanel={(rowData) => {
                    return (
                        <Container maxWidth="xl">
                            <Grid container spacing={2}>
                                <Grid size={{ xs: 8, md: 5 }} offset={{ xs: 1, md: 2 }}>
                                    <Box my={3}>
                                        <Typography
                                            variant="h6"
                                            component="p"
                                            color="secondary"
                                            gutterBottom
                                        >
                                            Customer Notes
                                        </Typography>
                                        <Typography variant="body1" component="p">
                                            {rowData?.case_notes || 'no notes'}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Container>
                    );
                }}
            />
        </Container>
    );
};

export default DischargedFromEngageList;
