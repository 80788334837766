import React from 'react';
import { makeStyles } from '@mui/styles';
import { CssBaseline, Box, Theme, Link, Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { onAuthStateChanged, getIdTokenResult } from 'firebase/auth';
import { auth, firestoreInstance } from '../config/ApiService';
import { useNavigate, Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AuthActions from 'redux/actions/AuthActions';
import AuthActionCreators from 'redux/creators/AuthActionCreators';
import { LOG_TYPES } from 'utils/Logger';
import { doc, getDoc } from 'firebase/firestore';
import { CLIENTS, USERS } from '../core/utils/collectionNames';
import { Client, User } from '@eql-ai/typescript-types';
import { Region, fetchRegions } from 'services/region';
import { retrievePermissions } from 'utils/AclGuard';
import { isLocalHost } from 'utils/AppEnv';

interface UserInfo extends User {
    agreement_type: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex'
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh'
    }
}));

const AuthLayout = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const hasWeakPassword = useSelector((state: any) => state.AuthReducer.hasWeakPassword);
    const storedRegions = useSelector((state: any) => state.AuthReducer.regions as Region[]);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const getUserRegion = async (
        region: string
    ): Promise<{ databaseId: string; regions: Region[] }> => {
        const loadRegions = storedRegions ?? (await fetchRegions()).data.regions;
        const databaseId = loadRegions.find(({ key }) => key === region)?.value;
        if (storedRegions !== null && !databaseId) {
            // If the stored regions don't find a matching databaseId, the
            // following block of code will attempt to re-call the function.
            // This time it will clear the stored regions and re-fetch remotely.
            dispatch(AuthActionCreators.setRegions(null));
            return getUserRegion(region);
        }

        if (!databaseId) {
            console.warn(
                'The region of this user cannot be found. Attempting to use the default instance.'
            );
        }

        return {
            databaseId: databaseId ?? '(default)',
            regions: loadRegions
        };
    };

    const getUserInfo = async (userId: string) => {
        try {
            const ref = doc(firestoreInstance(), USERS, userId);
            const query = await getDoc(ref);
            return { ...query.data(), id: query.id };
        } catch (e) {
            console.log(e);
        }
    };

    const getOrganisationInfo = async (clientId: string) => {
        try {
            const ref = doc(firestoreInstance(), CLIENTS, clientId);
            const query = await getDoc(ref);
            return { ...query.data(), id: query.id };
        } catch (e) {
            console.log(e);
        }
    };

    React.useEffect(() => {
        const unsub = onAuthStateChanged(auth, (user) => {
            if (!user) {
                return;
            }
            getIdTokenResult(user)
                .then(async (token) => {
                    const { claims } = token;
                    const { databaseId, regions } = await getUserRegion(claims.region as string);
                    firestoreInstance(databaseId);
                    const userInfo = (await getUserInfo(user.uid)) as UserInfo;
                    const orgInfo = (await getOrganisationInfo(userInfo.client_id)) as Client;
                    if (hasWeakPassword) {
                        // Snackbar Actions: change password now and dismiss buttons
                        const action = (key: any) => (
                            <>
                                <Link href="/forgotPassword" component={Button}>
                                    CHANGE NOW
                                </Link>
                                <Button onClick={() => closeSnackbar(key)}>DISMISS</Button>
                            </>
                        );

                        enqueueSnackbar(
                            "It appears that you're using a weak password. Please, update it ASAP as we'll enforce a new password policy soon.",
                            {
                                persist: true,
                                variant: 'warning',
                                action
                            }
                        );
                    }

                    dispatch({
                        type: AuthActions.SIGN_IN,
                        payload: {
                            accessLevel: token.claims.access_level ?? token.claims.role,
                            isAdmin: token.claims.isAdmin,
                            user: {
                                ...userInfo
                            },
                            organisation: {
                                name: orgInfo.name,
                                id: orgInfo.id,
                                agreement_type: orgInfo.agreement_type,
                                clinical_services_enabled:
                                    orgInfo.clinical_services_enabled || false,
                                validated: orgInfo.validated,
                                phio_agent: orgInfo.phio_agent
                            },
                            permissions: retrievePermissions(token),
                            regions,
                            databaseId,
                            region: claims.region ?? 'gb'
                        }
                    });
                    dispatch(
                        AuthActionCreators.logForAudit({
                            target: {
                                id: userInfo.id,
                                description: 'Logged into platform',
                                affectedUser: userInfo.email
                            },
                            type: LOG_TYPES.READ
                        })
                    );
                    // Check if user has region
                    // No region indicates permission has not upgraded
                    if (!token.claims.region && !isLocalHost(window.location.href)) {
                        navigate('/permission-upgrade');
                        return;
                    }
                    navigate('/dashboard/patients');
                })
                .catch((e) => {
                    console.log(e);
                    enqueueSnackbar(e.message, { variant: 'error' });
                });
        });
        return () => {
            unsub();
        };
        // eslint-disable-next-line
    }, [hasWeakPassword]);

    return (
        <div className={classes.root}>
            <CssBaseline />
            <main className={classes.content}>
                <Outlet />
                <Box pt={1}>{/* <Copyright /> */}</Box>
            </main>
        </div>
    );
};

export default AuthLayout;
