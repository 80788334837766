import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface FilterProps {
    options: { id: string; name: string }[];
    label?: string;
    value: string;
    onChange: (val: string) => void;
    fullWidth?: boolean;
}

const useStyles = makeStyles(() => ({
    formControl: {
        minWidth: '200px',
        width: '100%'
    }
}));

const Filter = ({ options, onChange, value, label, fullWidth }: FilterProps) => {
    const classes = useStyles();
    return (
        <FormControl fullWidth={fullWidth} variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">{label || 'Status'}</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                value={value}
                onChange={(evt) => onChange(evt.target.value)}
                id="demo-simple-select-outlined"
                label={label || 'Status'}
            >
                {options.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                        {option.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default Filter;
