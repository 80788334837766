import { createStore, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk';
import { persistReducer, persistStore } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
import storage from 'redux-persist/lib/storage';
import { appReducer } from 'redux/reducers/index';

const config = {
    key: 'root',
    storage
};

const persistedReducer = persistReducer(config, appReducer);

const store = createStore(persistedReducer, undefined, composeWithDevTools(applyMiddleware(thunk)));

export const persistor = persistStore(store);

export default store;
