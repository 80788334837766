import { useEffect, useState } from 'react';
import MaterialTable, { Column, MTableToolbar } from 'material-table';
import moment from 'moment';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Triage } from '@eql-ai/typescript-types';
import { TRIAGE } from 'core/utils/collectionNames';
import { firestoreInstance } from 'config/ApiService';
import {
    QueryConstraint,
    collection,
    doc,
    getDocs,
    limit,
    orderBy,
    query,
    serverTimestamp,
    updateDoc,
    where
} from 'firebase/firestore';
import { Backdrop, Box, Checkbox, CircularProgress, Container, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import Filter from 'views/triage/components/Filter';
import DateRangeSelector from 'views/triage/components/DateRangeSelector';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import { downloadPdfReport, triagePdfReport } from 'services/pdfService';
import { useSnackbar } from 'notistack';
import AuthActionCreators from 'redux/creators/AuthActionCreators';
import { LOG_TYPES } from 'utils/Logger';
import { endOfDay, startOfDay } from 'date-fns';

const TriageTable = (props: any) => {
    const { registrationConfig, client, regConfigLoading } = props;

    const [triages, setTriages] = useState<Triage[]>([]);
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(startOfDay(new Date()));
    const [endDate, setEndDate] = useState(endOfDay(new Date()));
    const [statusFilter, setStatusFilter] = useState('completed');
    const [downloadedFilter, setDownloadedFilter] = useState('all');
    const [actionedFilter, setActionedFilter] = useState('notActioned');

    const { gp_selection_mode, capture_nhs_number, capture_chi_number, custom_dropdown_title } =
        registrationConfig || {};

    const user = useSelector((state: RootState) => state.AuthReducer.user);
    const currentRegion = useSelector((state: RootState) => state.AuthReducer.currentRegion);

    const dispatch = useDispatch();

    const { enqueueSnackbar } = useSnackbar();

    const handleStartDateChange = (date: Date) => {
        setStartDate(startOfDay(date));
    };

    const handleEndDateChange = (date: Date) => {
        setEndDate(endOfDay(date));
    };

    const updateTriageDownloadState = async (triageId: string) => {
        const ref = doc(firestoreInstance(), TRIAGE, triageId);
        await updateDoc(ref, {
            download: true,
            downloaded_at: serverTimestamp()
        });
    };

    const updateTriageReviewedState = async (triageId: string, reviewed: boolean) => {
        const ref = doc(firestoreInstance(), TRIAGE, triageId);
        await updateDoc(ref, {
            reviewed,
            reviewed_at: serverTimestamp(),
            reviewed_by: user.email
        });

        dispatch(
            AuthActionCreators.logForAudit({
                target: {
                    id: user.id,
                    description: reviewed
                        ? 'Phio Access triage marked as reviewed'
                        : 'Phio Access triage marked as not reviewed',
                    affectedUser: triageId
                },
                type: LOG_TYPES.WORKFLOW
            })
        );
    };

    const onDownload = async (rowData: Triage) => {
        setLoading(true);

        try {
            if (rowData.id) {
                const result = await triagePdfReport({
                    triageId: rowData.id,
                    region: currentRegion
                });
                const { pdfAsBase64String, status } = result.data;

                if (status === 200) {
                    await downloadPdfReport(
                        pdfAsBase64String,
                        `${rowData.patient_first_name}_${rowData.patient_last_name}_phio_triage`
                    );

                    dispatch(
                        AuthActionCreators.logForAudit({
                            target: {
                                id: user.id,
                                description: 'PDF Report Downloaded',
                                affectedUser: rowData.id
                            },
                            type: LOG_TYPES.READ
                        })
                    );

                    if (!rowData.download) {
                        await updateTriageDownloadState(rowData.id);
                    }

                    fetchTriages();
                } else {
                    enqueueSnackbar('There was an issue accessing this url, please try again.', {
                        variant: 'error'
                    });
                }
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    const columns: Column<any>[] = [
        {
            field: 'reviewed',
            title: 'Reviewed',
            cellStyle: { minWidth: '100px' },
            render: (rowData) => (
                <Checkbox
                    checked={rowData.reviewed || false}
                    onChange={async (event) => {
                        setLoading(true);
                        await updateTriageReviewedState(rowData.id, event.target.checked);
                        await fetchTriages();
                        setLoading(false);
                    }}
                />
            )
        },
        {
            field: 'first_downloaded_at',
            title: 'First Downloaded',
            render: (rowData) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {rowData.first_downloaded_at}{' '}
                    {rowData.downloaded_at ? (
                        <CheckIcon sx={{ color: 'green', ml: 1 }} />
                    ) : (
                        <CloseIcon sx={{ color: 'red', ml: 1 }} />
                    )}
                </Box>
            ),
            cellStyle: { minWidth: '210px' },
            emptyValue: '-'
        },
        {
            field: 'id',
            title: 'UID',
            cellStyle: { minWidth: '250px' },
            emptyValue: '-'
        },
        {
            field: 'patient_name',
            title: 'Full Name',
            cellStyle: { minWidth: '250px' },
            emptyValue: '-'
        },
        {
            field: 'dob',
            title: 'DOB',
            cellStyle: { minWidth: '120px' },
            customFilterAndSearch: (term, rowData) => {
                const dateOfBirth = rowData?.dob;

                if (dateOfBirth !== 'missing') {
                    return dateOfBirth.includes(term);
                }
                return dateOfBirth.includes(term);
            },
            emptyValue: '-'
        },
        {
            field: 'triage_started',
            title: 'Started Time',
            cellStyle: { minWidth: '160px' },
            emptyValue: '-'
        },
        {
            field: 'triage_completed',
            title: 'Completed Time',
            cellStyle: { minWidth: '160px' },
            emptyValue: '-'
        },
        {
            field: 'case_status',
            title: 'Case Status',
            render: (rowData) => {
                return rowData.case_status[0].toUpperCase() + rowData.case_status.slice(1);
            },
            cellStyle: { minWidth: '120px' },
            emptyValue: '-'
        },
        {
            field: 'predicted_outcome',
            title: 'Outcome',
            cellStyle: { minWidth: '180px' },
            emptyValue: '-'
        },
        {
            field: 'gp_area',
            title: 'Area',
            hidden: !(
                gp_selection_mode === 'areas' ||
                gp_selection_mode === 'areas_and_gps' ||
                gp_selection_mode === 'areas_text'
            ),
            cellStyle: { minWidth: '180px' },
            emptyValue: '-'
        },
        {
            field: 'gp',
            title: 'GP',
            hidden: !(
                gp_selection_mode === 'areas_and_gps' ||
                gp_selection_mode === 'gps' ||
                gp_selection_mode === 'gps_text' ||
                gp_selection_mode === 'gps_text_and_gp_postcode_text'
            ),
            cellStyle: { minWidth: '180px' },
            emptyValue: '-'
        },
        {
            field: 'gp_postcode',
            title: 'GP Postcode',
            hidden: !(
                gp_selection_mode === 'gp_postcode_text' ||
                gp_selection_mode === 'gps_text_and_gp_postcode_text'
            ),
            cellStyle: { minWidth: '180px' },
            emptyValue: '-'
        },
        {
            field: 'hospital',
            title: 'Hospital',
            hidden: gp_selection_mode !== 'hospitals',
            cellStyle: { minWidth: '180px' },
            emptyValue: '-'
        },
        {
            field: 'policy_number',
            title: 'Policy Number',
            hidden: gp_selection_mode !== 'policy_number_text',
            cellStyle: { minWidth: '180px' },
            emptyValue: '-'
        },
        {
            field: 'custom_dropdown',
            title: custom_dropdown_title, // Custom dropdown
            hidden: gp_selection_mode !== 'custom_dropdown',
            cellStyle: { minWidth: '180px' },
            emptyValue: '-'
        },
        {
            field: 'nhs_number',
            title: 'NHS No.',
            hidden: !capture_nhs_number,
            cellStyle: { minWidth: '180px' },
            emptyValue: '-'
        },
        {
            field: 'chi_number',
            title: 'CHI No.',
            hidden: !capture_chi_number,
            cellStyle: { minWidth: '180px' },
            emptyValue: '-'
        },
        {
            field: 'patient.member_id',
            title: 'Member ID',
            hidden: !client.third_party_f2f_enabled,
            cellStyle: { minWidth: '350px' },
            emptyValue: '-'
        },
        {
            field: 'patient.policy_holder_name',
            title: 'Policy Holder Name',
            hidden: !client.third_party_f2f_enabled,
            cellStyle: { minWidth: '180px' },
            emptyValue: '-'
        }
    ];

    const fetchTriages = async () => {
        try {
            setLoading(true);

            const ref = collection(firestoreInstance(), TRIAGE);
            const constraints: QueryConstraint[] = [
                where('client_id', '==', client.id),
                where('created_at', '>=', startDate),
                where('created_at', '<=', endDate),
                orderBy('created_at', 'desc'),
                limit(500)
            ];

            if (statusFilter !== 'all') {
                constraints.unshift(where('case_status', '==', statusFilter));
            }

            if (downloadedFilter !== 'all') {
                constraints.unshift(where('download', '==', downloadedFilter === 'true'));
            }

            if (actionedFilter !== 'all') {
                constraints.unshift(
                    where('reviewed', '==', actionedFilter === 'actioned' ? true : false)
                );
            }

            const q = query(ref, ...constraints);
            const snapshots = await getDocs(q);
            let triageData: any[] = [];

            snapshots.forEach((snapshot) => {
                const data = snapshot.data();

                triageData.push({
                    ...data,
                    id: snapshot.id,
                    patient_name: `${data.patient_first_name} ${data.patient_last_name}`,
                    first_downloaded_at: data.downloaded_at
                        ? moment(data.downloaded_at.toDate()).format('DD/MM/YYYY HH:mm')
                        : 'Not downloaded yet',
                    triage_started: data.created_at
                        ? moment(data.created_at.toDate()).format('DD/MM/YYYY HH:mm')
                        : '-',
                    triage_completed: data.completed_at
                        ? moment(data.completed_at.toDate()).format('DD/MM/YYYY HH:mm')
                        : '-',
                    dob:
                        data.patient_dob !== 'missing'
                            ? moment(data.patient_dob).format('DD/MM/YYYY')
                            : 'Missing',
                    gp_area: data.gp_area === 'missing' ? '-' : data.gp_area,
                    gp: data.gp === 'missing' ? '-' : data.gp,
                    gp_postcode: data.gp_postcode === 'missing' ? '-' : data.gp_postcode,
                    hospital: data.hospital === 'missing' ? '-' : data.hospital,
                    policy_number: data.policy_number === 'missing' ? '-' : data.policy_number,
                    custom_dropdown:
                        data.custom_dropdown === 'missing' ? '-' : data.custom_dropdown,
                    nhs_number: data.nhs_number === 'missing' ? '-' : data.nhs_number
                });
            });

            setTriages(triageData);
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        (async () => {
            await fetchTriages();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client.id, statusFilter, downloadedFilter, startDate, endDate, actionedFilter]);

    const ToolbarFilters = () => (
        <Container maxWidth="xl">
            <Grid container spacing={2} mt={2} mb={3}>
                <Grid size={{ xs: 6, md: 5, lg: 4 }}>
                    <DateRangeSelector
                        label="Start Date"
                        defaultDate={startDate}
                        onDateChange={handleStartDateChange}
                        fullWidth
                    />
                </Grid>
                <Grid size={{ xs: 6, md: 5, lg: 4 }}>
                    <DateRangeSelector
                        label="End Date"
                        onDateChange={handleEndDateChange}
                        defaultDate={endDate}
                        fullWidth
                    />
                </Grid>
                <Grid size={{ xs: 6, md: 5, lg: 4 }}></Grid>
                <Grid size={{ xs: 6, md: 5, lg: 4 }}>
                    <Filter
                        label="Downloaded Status"
                        options={[
                            { id: 'true', name: 'Downloaded' },
                            { id: 'false', name: 'Not Downloaded' },
                            { id: 'all', name: 'All' }
                        ]}
                        onChange={setDownloadedFilter}
                        fullWidth
                        value={downloadedFilter}
                    />
                </Grid>
                <Grid size={{ xs: 6, md: 5, lg: 4 }}>
                    <Filter
                        label="Triage Status"
                        options={[
                            { id: 'open', name: 'Open' },
                            { id: 'closed', name: 'Closed' },
                            { id: 'completed', name: 'Completed' },
                            { id: 'all', name: 'All' }
                        ]}
                        onChange={setStatusFilter}
                        fullWidth
                        value={statusFilter}
                    />
                </Grid>
                <Grid size={{ xs: 6, md: 5, lg: 4 }}>
                    <Filter
                        label="Reviewed Status"
                        options={[
                            { id: 'all', name: 'All' },
                            { id: 'actioned', name: 'Actioned' },
                            { id: 'notActioned', name: 'Not Actioned' }
                        ]}
                        onChange={setActionedFilter}
                        fullWidth
                        value={actionedFilter}
                    />
                </Grid>
            </Grid>
        </Container>
    );

    return (
        <Box>
            <MaterialTable
                title={
                    <>
                        <Typography variant="h6">Triages</Typography>
                        <Typography variant="subtitle2" color="gray">
                            Table capped to 500 results.
                        </Typography>
                    </>
                }
                data={triages}
                columns={columns}
                options={{
                    pageSize: 20,
                    pageSizeOptions: [20, 40, 60],
                    searchFieldVariant: 'outlined',
                    exportButton: true,
                    exportAllData: true,
                    exportFileName: `Phio Triages - ${startDate} - ${endDate}`,
                    actionsCellStyle: {
                        minWidth: '88px',
                        paddingLeft: '24px',
                        paddingRight: '24px'
                    }
                }}
                components={{
                    Toolbar: (props) => (
                        <>
                            <MTableToolbar {...props} />
                            <ToolbarFilters />
                        </>
                    )
                }}
                actions={[
                    {
                        icon: 'save_alt',
                        tooltip: 'Download pdf',
                        onClick: (event, rowData) => {
                            onDownload(rowData);
                        }
                    }
                ]}
            />
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={loading || regConfigLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );
};

export default TriageTable;
